import React from 'react';
import CartSVG from '../svgs/CartSVG';
import AddCartSVG from '../svgs/addCartSVG';
import FacebookSVG from '../svgs/facebookSVG';
import checkMarkSVG from '../svgs/checkMarkSVG';

function SVG({ name, width, height, fill }) {
	let RenderedSVG;
	switch (name) {
		case 'cart':
			RenderedSVG = CartSVG;
			break;
		case 'addCart':
			RenderedSVG = AddCartSVG;
			break;
		case 'facebook':
			RenderedSVG = FacebookSVG;
			break;
		case 'checkmark':
			RenderedSVG = checkMarkSVG;
			break;
		default:
			throw new Error('Not a valid svg');
	}
	return <RenderedSVG width={width} height={height} fill={fill} />;
}

export default SVG;
