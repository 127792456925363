import React from 'react';
import { Link } from 'gatsby';

import styles from './mobileMenu.module.css';

function MobileMenu({ active, toggleMobileMenu, toggleContactModal }) {
	const handleClick = () => {
		toggleContactModal();
		toggleMobileMenu();
	};

	return active ? (
		<nav className={styles.wrapper}>
			<Link className={styles.link} onClick={toggleMobileMenu} to="/#products">
				ผลิตภัณฑ์ของเรา
			</Link>
			<a className={styles.link} onClick={handleClick}>
				ติดต่อเรา
			</a>
			<Link className={styles.link} to="/payment">
				การชำระเงิน
			</Link>
			<Link className={styles.link} to="/checkout">
				ตะกร้าของคุณ
			</Link>
		</nav>
	) : null;
}

export default MobileMenu;
