import React, { useContext, useState, useEffect } from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';

import {
	GlobalStateContext,
	GlobalDispatchContext,
} from '../context/globalContextProvider';
import SVG from './svg';

import styles from './header.module.css';
import Cart from './cart';
import MobileMenu from './mobileMenu';
import Modal from './modal';
import ContactForm from './contactForm';

const Header = ({ siteTitle }) => {
	const [modal, setModal] = useState(false);
	const [mobileMenu, setMobileMenu] = useState(false);
	const [cart, setCart] = useState(false);
	const [isCheckoutActive, setIsCheckoutActive] = useState(false);
	const state = useContext(GlobalStateContext);
	const dispatch = useContext(GlobalDispatchContext);

	const getItemsTotal = () => {
		const items = state.items;
		return items.reduce((curr, next) => curr + parseInt(next.amount), 0);
	};

	const handleMouseEnter = () => {
		setCart(true);
	};

	const handleMouseLeave = () => {
		setCart(false);
	};

	const handleTogglerClick = () => {
		setMobileMenu(!mobileMenu);
	};

	const toggleContactModal = () => {
		setModal(!modal);
	};

	const toggleMobileMenu = () => {
		setMobileMenu(false);
	};

	useEffect(() => {
		state.items.length < 1
			? setIsCheckoutActive(false)
			: setIsCheckoutActive(true);
	}, [state]);

	return (
		<header className={styles.header}>
			<div className={styles.wrapper}>
				<Link to="/">
					<img
						className={styles.logo}
						src="/images/logo.png"
						height={22}
						alt="Logo"
					/>
				</Link>

				<nav className={styles.nav}>
					<Link className={styles.link} to="/#products">
						ผลิตภัณฑ์ของเรา
					</Link>

					<button className={styles.link} onClick={toggleContactModal}>
						ติดต่อเรา
					</button>
					<Link className={styles.link} to="/payment">
						การชำระเงิน
					</Link>

					{isCheckoutActive ? (
						<Link className={styles.link} id="checkoutButton" to="/checkout">
							ตะกร้าของคุณ
						</Link>
					) : (
						<button className={styles.link} disabled>
							ตะกร้าของคุณ
						</button>
					)}
					<div
						className={styles.link}
						style={{ cursor: 'unset' }}
						onMouseEnter={handleMouseEnter}
						onMouseLeave={handleMouseLeave}
					>
						<SVG name="cart" width="20" height="20" fill="#666" />
						{state.items.length >= 1 ? (
							<span className={styles.cartNotification}>{getItemsTotal()}</span>
						) : null}

						<Cart items={state.items} active={cart} dispatch={dispatch} />
					</div>
				</nav>

				{isCheckoutActive ? (
					<Link className={styles.checkoutMobile} to="/checkout">
						ตะกร้าของคุณ
					</Link>
				) : (
					<button className={styles.checkoutMobile} disabled>
						ตะกร้าของคุณ
					</button>
				)}

				<Link className={styles.cartMobile} to="/cart">
					<SVG name="cart" width="20" height="20" fill="#666" />
					{state.items.length >= 1 ? (
						<span className={styles.cartNotification}>{getItemsTotal()}</span>
					) : null}
				</Link>

				<button className={styles.toggler} onClick={handleTogglerClick}>
					<div className={styles.hamburger}></div>
					<div className={styles.hamburger}></div>
					<div className={styles.hamburger}></div>
				</button>
			</div>
			<MobileMenu
				active={mobileMenu}
				toggleMobileMenu={toggleMobileMenu}
				toggleContactModal={toggleContactModal}
			/>
			<Modal
				type={<ContactForm toggleContactModal={toggleContactModal} />}
				active={modal}
			/>
		</header>
	);
};

Header.propTypes = {
	siteTitle: PropTypes.string,
};

Header.defaultProps = {
	siteTitle: ``,
};

export default Header;
