import React, { Component } from 'react';
import { Link } from 'gatsby';

import styles from './cart.module.css';

class Cart extends Component {
	handleRemoveClick = e => {
		const { dispatch } = this.props;
		const path = e.target.dataset.itemPath;
		dispatch({ type: 'DELETE_CART_ITEM', path });
	};

	handleQuantityChange = e => {
		const { dispatch } = this.props;
		const path = e.target.dataset.itemPath;
		const amount = e.target.value;
		dispatch({ type: 'UPDATE_CART_ITEM', path, amount });
	};

	render() {
		const { items, active } = this.props;
		const quantities = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

		return active && items.length >= 1 ? (
			<section className={styles.section}>
				<h4 className={styles.title}>ตะกร้าของคุณ</h4>
				<div className={styles.items}>
					{items.map(item => {
						const { thumbnail, title, size, price, amount, path } = item;
						const total = price * amount;
						return (
							<div className={styles.itemWrapper} key={path}>
								<div className={styles.thumbnailWrapper}>
									<Link to={path}>
										<img
											className={styles.thumbnail}
											src={thumbnail}
											alt={title}
										/>
									</Link>
								</div>
								<div>
									<Link to={path}>
										<h5>{title}</h5>
									</Link>

									<div className={styles.details}>ขนาดสินค้า {size}ml</div>
									<div className={styles.details}>
										<span>จำนวน </span>
										<select
											name="amount"
											value={amount}
											data-item-path={path}
											onChange={this.handleQuantityChange}
										>
											{quantities.map(quantity => (
												<option value={quantity} key={quantity}>
													{quantity}
												</option>
											))}
										</select>
									</div>
									<button
										className={styles.removeButton}
										onClick={this.handleRemoveClick}
										data-item-path={path}
									>
										ลบสินค้า
									</button>
									<div className={styles.price}>฿ {total}</div>
								</div>
							</div>
						);
					})}
				</div>
				<Link className={styles.button} to="/checkout">
					เช็คเอาท์
				</Link>
			</section>
		) : null;
	}
}

export default Cart;
