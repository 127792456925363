import React from 'react';

import styles from './modal.module.css';

function Modal({ type, active }) {
	return (
		<section
			className={styles.section}
			style={active ? { display: 'flex' } : { display: 'none' }}
		>
			<div className={styles.wrapper}>{type}</div>
		</section>
	);
}

export default Modal;
