import React from 'react';

import styles from './footer.module.css';
import SVG from './svg';
import { Link } from 'gatsby';

function Footer() {
	return (
		<footer className={styles.footer}>
			<div>
				<h4>ติดต่อเราได้ที่</h4>
				<p>contact@malaiherbal.com</p>
				<a
					href="https://www.facebook.com/malaiherbalhairproduct/"
					target="_blank"
					rel="noopener noreferrer"
				>
					<div className={styles.socialMediaWrapper}>
						<span className={styles.socialMediaLead}>ติดตามเราบน</span>
						<SVG name="facebook" width="30" height="30" fill="#555" />
					</div>
				</a>
			</div>

			<div>
				<h4>เวลาทำการ</h4>
				<p>จันทร์ - ศุกร์ 8:00 ถึง 18:00</p>
				<p>เสาร์ - อาทิตย์ 10:00 ถึง 13:00</p>
			</div>

			<nav className={styles.nav}>
				<Link className={styles.link} to="/#products">
					ผลิตภัณฑ์ของเรา
				</Link>
				<Link className={styles.link} to="/payment">
					วิธีการชำระเงิน
				</Link>
				<Link className={styles.link} to="/checkout">
					ตะกร้าของคุณ
				</Link>
			</nav>
		</footer>
	);
}

export default Footer;
