import React, { useContext } from 'react';
import {
	GlobalStateContext,
	GlobalDispatchContext,
} from '../context/globalContextProvider';
import styles from './TopBar.module.css';

function TopBar() {
	const state = useContext(GlobalStateContext);
	const dispatch = useContext(GlobalDispatchContext);
	const { message, active } = state.topBar;

	const handleClick = () => {
		dispatch({ type: 'DESTROY_TOPBAR' });
	};

	return active ? (
		<section className={styles.section}>
			<div className={styles.wrapper}>
				<small>{message}</small>
				<button className={styles.button} onClick={handleClick}>
					&#10006;
				</button>
			</div>
		</section>
	) : null;
}

export default TopBar;
