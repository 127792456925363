import React, { Component } from 'react';
import { encode } from '../helpers';
import { GlobalDispatchContext } from '../context/globalContextProvider';

import styles from './contactForm.module.css';

class ContactForm extends Component {
	state = {
		firstNameContact: '',
		lastNameContact: '',
		emailContact: '',
		messageContact: '',
	};

	handleClick = () => {
		this.props.toggleContactModal();
	};

	handleChange = e => {
		this.setState({ [e.target.name]: e.target.value });
	};

	handleSubmit = e => {
		if (this.validate()) {
			fetch('/', {
				method: 'POST',
				headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
				body: encode({ 'form-name': 'contact', ...this.state }),
			})
				.then(() => {
					this.props.toggleContactModal();
					this.context({
						type: 'CREATE_TOPBAR',
						message:
							'Your submission has been successfully received. We will get back to you soon.',
					});
				})
				.catch(error => alert(error));
		}
		e.preventDefault();
	};

	validate = () => {
		const state = this.state;
		let isValid = true;
		for (let [key, input] of Object.entries(this.state)) {
			if (input === '') {
				const errorName = `${key}Error`;
				isValid = false;
				this.setState({ ...state, [errorName]: 'This field is required' });
			}
		}
		return isValid;
	};

	static contextType = GlobalDispatchContext;

	render() {
		const {
			firstNameContact,
			lastNameContact,
			emailContact,
			messageContact,
		} = this.state;
		return (
			<section>
				<form
					className={styles.form}
					name="contact"
					method="POST"
					data-netlify="true"
					netlify-honeypot="bot-field"
					id="contact"
					onSubmit={this.handleSubmit}
				>
					<input type="hidden" name="bot-field" />
					<input type="hidden" name="form-name" value="contact" />
					<div className={styles.topRow}>
						<h4 className={styles.title}>ข้อมูลเพื่อการติดต่อกลับ</h4>
						<button
							className={styles.close}
							type="button"
							onClick={this.handleClick}
						>
							&#10006;
						</button>
					</div>
					<div className={styles.row}>
						<div className={styles.col}>
							<label className={styles.label} htmlFor="firstNameContact">
								ชื่อ
							</label>
							<input
								className={styles.input}
								type="text"
								placeholder={
									this.state.firstNameContactError || 'กรุณากรอกชื่อของคุณ'
								}
								id="firstNameContact"
								name="firstNameContact"
								value={firstNameContact}
								onChange={this.handleChange}
							/>
						</div>

						<div className={styles.col}>
							<label className={styles.label} htmlFor="lastNameContact">
								นามสกุล
							</label>
							<input
								className={styles.input}
								type="text"
								placeholder={
									this.state.lastNameContactError || 'กรุณากรอกนามสกุลของคุณ'
								}
								id="lastNameContact"
								name="lastNameContact"
								value={lastNameContact}
								onChange={this.handleChange}
							/>
						</div>
					</div>
					<div>
						<label className={styles.label} htmlFor="emailContact">
							อีเมลล์
						</label>
						<input
							className={styles.input}
							type="emailContact"
							placeholder={
								this.state.emailContactError || 'กรุณากรอกอีเมลล์ของคุณ'
							}
							id="emailContact"
							name="emailContact"
							value={emailContact}
							onChange={this.handleChange}
						/>
					</div>

					<div>
						<label className={styles.label} htmlFor="messageContact">
							ข้อความที่ต้องการฝากไว้
						</label>
						<textarea
							className={[styles.input, styles.textarea].join(' ')}
							type="text"
							placeholder={
								this.state.messageContactError ||
								'กรุณาพิมพ์ข้อความที่ต้องการฝากไว้'
							}
							id="messageContact"
							name="messageContact"
							value={messageContact}
							onChange={this.handleChange}
						></textarea>
					</div>
					<input
						className={styles.button}
						type="submit"
						value="ส่งข้อความ"
						form="contact"
					/>
				</form>
			</section>
		);
	}
}

export default ContactForm;
